






















































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';
import ContractSavingsProfilingResultsViewModel
  from '@/vue-app/view-models/components/contract-savings/contract-savings-profiling-results-view-model';

@Component({
  name: 'ContractSavingsProfilingResults',
  components: {
    CustomAutocomplete,
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class ContractSavingsProfilingResults extends Vue {
  profiling_results_view_model = Vue.observable(
    new ContractSavingsProfilingResultsViewModel(this),
  );

  @Watch('profiling_results_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  async created() {
    await this.profiling_results_view_model.initialize(getScreenSizeVariant());
  }
}

